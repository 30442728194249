<template>

    <v-row class="mt-4">
        <v-col cols="12">
            <v-card
            v-if="loggedIn"
            class="mx-auto"
                >
    
                <v-toolbar
                    ligth
                    cards                
                    flat
                    color="green lighten-4"
                >
                    <v-card-title class="text-h6 font-weight-regular">
                    Companies
                    </v-card-title>
                    <v-spacer> </v-spacer>
                    <v-btn
                        :disabled="currentUser.roleLevel < 500"
                        color="primary"
                        dark
                        fab
                        class="mt-10"
                        @click="addItem"
                    >
                        <v-icon dark>
                            mdi-playlist-plus
                        </v-icon>
                    </v-btn>             
                </v-toolbar>
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                        <v-dialog v-model="ctrlConfirmDel.isVisisble" :width="ctrlConfirmDel.width">
                            <ctrlConfirmDel 
                                ref="ctrlConfirmDel" 
                                :text= "ctrlConfirmDel.text"
                                :delName="ctrlConfirmDel.delName"
                                @deleteItemConfirm="deleteItemConfirm"
                                @close="closeConfirmDel">
                            </ctrlConfirmDel>              
                        </v-dialog>
                        <v-dialog v-model="ctrlAEItem.isVisisble" :width="ctrlAEItem.width" >
                            <ctrlAddEditItem 
                                ref="ctrlAEItem" 
                                :item="ctrlAEItem.item"
                                :options="ctrlAEItem.options"
                                :availableRoles="availableRoles"
                                :isVisible.sync="ctrlAEItem.isVisisble"                                
                                @close="ctrlAEItem.isVisisble = false">
                            </ctrlAddEditItem>              
                        </v-dialog>       
                        <v-dialog v-model="ctrlAEU2C.isVisisble" :width="ctrlAEU2C.width" >
                            <ctrlAddEditCompany2User 
                                ref="ctrlAEU2C" 
                                :item="ctrlAEU2C.item"
                                :options="ctrlAEU2C.options"
                                :isVisible="ctrlAEU2C.isVisisble"                              
                                @close="ctrlAEU2C.isVisisble = false">
                            </ctrlAddEditCompany2User>              
                        </v-dialog>       
                                         
                    <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="search"
                    >
                    
                         <template v-slot:item.name="{ item }" @click="gotoCompany">
                            
                            <router-link :to="{ name: 'LoadCompany', params: { uid: item.uid } }">
                                {{ item.name }}
                            </router-link>
                        </template>
                    
                        <template v-slot:item.enabled="{ item }">
                             <v-switch
                                v-model="item.enabled"
                                :disabled="(!access.edit_accept || item.roleLevel > currentUser.roleLevel) && currentUser.id != item.id"
                                @change="enabledChanged(item)"
                            ></v-switch>
                        </template>
                        <template v-slot:item.actions="{ item }">
                        <v-icon    
                            :disabled="(!access.edit_accept || item.roleLevel > currentUser.roleLevel) && currentUser.id != item.id"
                            class="mr-2"
                            @click="editItem(item)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            :disabled="!access.delete_accept || item.roleLevel > currentUser.roleLevel"
                            @click="deleteItem(item)"
                        >
                            mdi-delete
                        </v-icon>
                        </template>               
                        <template  v-slot:item.links="{ item }">
                            <v-icon
                                :disabled="currentUser.roleLevel < 750 || item.roleLevel > currentUser.roleLevel"
                                large
                                @click="linkUser(item)"
                            >
                                mdi-link-box
                            </v-icon>
                        </template>
                </v-data-table>
  
            </v-card>
        </v-col>
    </v-row>

</template>


<script>

// import helper from "../../../helper/index"

export default {
    
    components: {
        ctrlAddEditItem: () => import('../../../components/ctrl/addEditCompany.vue'),
        ctrlAddEditCompany2User: () => import('../../../components/ctrl/addEditUser2Company.vue'),        
         ctrlConfirmDel: () => import('../../../components/ctrl/confirmDeleteItem.vue'),

    },  


    data () {
        return {
            name: "CompanyViewer",
            // access: {
            //     edit_accept: undefined
            // },
            expanded: [],
            errMsg:'',
            defaultKeyCodes:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
            search: '',
            headers: [
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Name', align: 'start',sortable: false, value: 'name',},
                { text: 'Straße', value: 'street' },
                { text: 'Stadt', value: 'town' },
                { text: 'Benutzer',sortable: false, value: 'links' },
                { text: 'Aktiv', value: 'enabled' },
    
            ],
            editedIndex: -1,
            ctrlAEItem:{
                isVisisble: false,
                width: 900,
                item:{},
                options:{
                    title: 'Benutzer',  
                    isEdit: false
                }
            },
            ctrlAEU2C:{
                isVisisble: false,
                width: 900,
                company:{},
                options:{
                    title: 'Linked User',  
                    isEdit: false
                }
            },                  
            ctrlConfirmDel:{
                width: 500,
                isVisisble: false,
                text: "Wollen Sie den Betrieb wirklich löschen?" ,
                delName: '',
                item: undefined
            },
        }
    },

  
    computed: {


        loggedIn: function() {        
            return this.$store.state.auth.status.loggedIn;       
        },

        currentUser() {
            console.debug("user", this.$store.state.auth.user)
            return this.$store.state.auth.user;
        },

        items(){
            console.debug("items", this.$store.state.management.companies)
            return this.$store.state.management.companies;
        },

        access(){
            
            return this.$store.state.management.access;
        }, 
        
        availableRoles(){
            
            return this.$store.state.management.availableRoles;
        },     

         
    },
    methods:{


        addItem () {
            console.debug('addItem');
            this.ctrlAEItem.options.isEdit = false;
            this.ctrlAEItem.item = undefined
            this.ctrlAEItem.isVisisble = !this.ctrlAEItem.isVisisble;
        },        
        editItem (item) {
            console.debug('editItem',item);
            this.ctrlAEItem.options.isEdit = true;
            this.ctrlAEItem.item = item;
            this.ctrlAEItem.isVisisble = !this.ctrlAEItem.isVisisble;
        },


        deleteItem (item) {
            console.debug('deleteItem', item);
            this.ctrlConfirmDel.delName = item.username
            this.ctrlConfirmDel.isVisisble = true;
            this.ctrlConfirmDel.item = item;
        },

        closeConfirmDel(){
             console.debug('closeConfirmDel');
             this.ctrlConfirmDel.isVisisble = false;        
        },        

        deleteItemConfirm () {
            console.debug('deleteItemConfirm');
           this.$store.dispatch("management/deleteApiSetStore", {path:"api/management/company", param:{ store:"companies", 'id':this.ctrlConfirmDel.item.id}})
            .then((response)=>{ 

                console.log("Company deleted:", response)
                this.ctrlConfirmDel.isVisisble = false; 

            })
            .catch((error)=>{
                console.error(error);
            })

        },

        enabledChanged(item){
            console.debug('enabledChanged', item);
            this.$store.dispatch('management/putApiSetStore', {path:"api/management/company", param:{ store:"companies", data:item}})
            .then((response)=>{ 

                console.log("enabledChanged:", response)

            })
            .catch((error)=>{
                console.error(error);
            })

 
        },

 
        linkUser(item){
          console.debug('linkUser', item);
          this.ctrlAEU2C.item = item;
          this.ctrlAEU2C.isVisisble = true;
            
        },

        gotoCompany(item){
            console.debug('companyChanged', item);
            this.$router.push({ name: 'LoadCompany' , params: { uid: item.uid } })  
        },

        refreshContent: function(){
            console.debug('refreshContent');
 
            this.$store.dispatch("management/getApiSetStore", {path:"api/management/access", param:{store:"access", content:"companies"}
            }).then( ()=>{ 
                return this.$store.dispatch("management/getApiSetStore", {path:"api/management/companies", param:{store:"companies"}})
            }).then( ()=>{ 
                   console.debug('sdsdsd')  
            }).catch((error)=>{
                console.error(error);
            })
 
        },
    },
    mounted() {

        //this.refreshNavbar();
        console.debug('mounted');
        this.refreshContent();
    }
}
</script>